<template>
  <div>
    <Banner></Banner>
    <WhyUs></WhyUs>
    <Services id="services"></Services>
    <!-- <Statistics></Statistics> -->
    <Unleashing></Unleashing>
    <Query></Query>
  </div>
</template>

<script>
import Banner from "@/components/home/banner";
import WhyUs from "@/components/home/why-choose-us";
import Services from "@/components/home/services";
import Statistics from "@/components/home/statistics";
import Query from "@/components/home/query";
import Unleashing from "@/components/home/unleashing";
export default {
  name: "Trecent",
  metaInfo: {
    title: "Trecent Systems | Exploring, Innovating, Transforming",
    titleTemplate: null,
    meta: [
      {
        vmid: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        name: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        content:
          "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
      },
    ],
  },
  components: {
    Banner,
    WhyUs,
    Services,
    Statistics,
    Query,
    Unleashing,
  },
};
</script>