<template>
  <!-- Start Free Trial Area -->
  <section class="free-trial-area">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="free-trial-image">
            <img src="@/assets/img/free-trial-img.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="free-trial-content">
            <h2>Let's get started with your requirements</h2>

            <form class="newsletter-form" @submit.prevent="submitContact">
              <input
                type="email"
                class="input-newsletter"
                v-model="contact.email"
                placeholder="Enter your email address"
                required
              />
              <button type="submit">Let's Connect</button>
            </form>

            <p>
              Don't worry, your email is safe with us. Our experts will get in
              touch with you shortly via email.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="shape2 rotateme">
      <img src="@/assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="@/assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7"><img src="@/assets/img/shape4.svg" alt="shape" /></div>
    <div class="shape8 rotateme">
      <img src="@/assets/img/shape2.svg" alt="shape" />
    </div>
  </section>
  <!-- End Free Trial Area -->
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "Trial",
  data() {
    return {
      contact: {},
    };
  },
  methods: {
    submitContact() {
      this.sockets.subscribe("ERROR:CONTACT_SUBMIT", () => {
        Swal.fire(
          "Oh Sorry!",
          "You message did not reach to us. Try after sometime.",
          "error"
        );
      });

      this.sockets.subscribe("RESPONSE:CONTACT_SUBMIT", () => {
        Swal.fire(
          "We just received that!",
          "Thank you for reaching out. We'll connect very soon.",
          "success"
        );
        this.contact = {};
      });

      this.$socket.emit("REQUEST:CONTACT_SUBMIT", this.contact);
    },
  },
};
</script>