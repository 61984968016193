import Vue from 'vue'
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import VueFeather from 'vue-feather';
import vWow from 'v-wow';
import VueCarousel from 'vue-carousel';
import Toasted from 'vue-toasted';
import App from './App.vue'
import './assets/style/custom.scss';
import { router } from './router';
import store from './store';
import SocketIO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO('https://trecent.in', { transports: ['websocket'] }),
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
  }
}))

Vue.use(VueMeta)
Vue.use(BootstrapVue);
Vue.use(VueFeather);
Vue.use(VueRouter);
Vue.use(vWow);
Vue.use(VueCarousel);
Vue.use(Toasted)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
