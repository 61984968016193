import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoading: false
    },
    getters: {
        isLoading: (state) => state.isLoading
    },
    mutations: {
        SET_LOADER: (state, data) => {
            state.isLoading = data
        }
    },
    actions: {
        SET_LOADER: ({ commit }, data) => {
            commit('SET_LOADER', data)
        }
    }
});