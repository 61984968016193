<template>
  <div>
    <Header v-if="$route.name !== 'NotFound'"></Header>
    <PreLoader v-if="$store.getters.isLoading" />
    <router-view></router-view>
    <Footer v-if="$route.name !== 'NotFound'"></Footer>
  </div>
</template>

<script>
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import PreLoader from "./components/layout/PreLoader";

export default {
  name: "app",
  metaInfo: {
    title: "Trecent Systems | Exploring, Innovating, Transforming",
    titleTemplate: null,
    meta: [
      {
        vmid: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        name: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        content:
          "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
      },
    ],
  },
  components: {
    Header,
    Footer,
    PreLoader,
  },
  mounted() {
    this.$store.dispatch("SET_LOADER", true);
    setTimeout(() => {
      this.$store.dispatch("SET_LOADER", false);
    }, 2000);
  },
};
</script>
