<template>
  <div>
    <!-- Start Page Title -->
    <div class="page-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Discover your new career</h1>
            <h4 class="mt-2">Explore opportunities with Trecent!</h4>
          </div>
        </div>
      </div>

      <div class="shape1">
        <img src="../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img src="../assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../assets/img/shape2.svg" alt="shape" />
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start Content Area -->
    <section class="blog-area ptb-80">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="agency-about-img">
              <img src="@/assets/images/careers.png" alt="image" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="agency-about-content">
              <h2>Working at Trecent</h2>
              <div class="bar"></div>
              <p>
                Trecent earns respect from its customers by being committed to
                speed, quality and flexibility. We strive to build excellent
                products by working with a passion and disciplined
                collaboration.
              </p>
              <p>
                You will be working in an encouraging and challenging
                environment, taking advantage of the latest technology to
                develop great applications.
              </p>
              <p>
                We are always looking for experts like you to join our team and
                thrilling journey of development to production.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <div class="agency-about-content">
              <h2 class="mt-2">Latest Openings</h2>
              <div class="bar"></div>
            </div>
          </div>
        </div>
        <div class="row" v-if="jobs.length > 0">
          <div v-for="(job, i) in jobs" :key="i" class="col-lg-4 col-md-6">
            <div class="single-blog-post-box">
              <div class="entry-post-content">
                <div class="entry-meta">
                  <ul>
                    <li>
                      <small>Posted on: {{ job.date }}</small>
                    </li>
                  </ul>
                </div>

                <h3>
                  <a href="javascript:void()">{{ job.position }}</a>
                </h3>

                <hr />
                <p>Location: {{ job.location }}</p>
                <p>Experience: {{ job.experience }}</p>
                <p>Skills: {{ job.skills }}</p>
                <p>Qualification: {{ job.qualification }}</p>
                <hr />
                <div class="text-center">
                  <a v-b-modal.apply-form href="javascript:void()" class="learn-more-btn" @click="selectedJob = job">Apply
                    Now <feather type="plus"></feather></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <h6 class="p-5">No new openings yet!</h6>
          </div>
        </div>
      </div>
    </section>
    <!-- End Content Area -->

    <!-- Start Apply Modal -->
    <b-modal centered id="apply-form" size="xl" title="" hide-footer>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h5>Position: {{ selectedJob.position }}</h5>
          <p>Location: Pune, Maharashtra, India.</p>
          <hr />
          <h6>Minimum Qualifications:</h6>
          <ul>
            <li>
              Bachelor's degree CS/IT or
              any equivalent degree.
            </li>
          </ul>

          <hr />
          <h6>About the job</h6>
          <p>
            {{ selectedJob.aboutJob }}
          </p>

          <hr />
          <h6>Responsibilities</h6>
          <p>
          <ul>
            <li v-for="(each, i) in String(selectedJob.responsibilities).split('.')" :key="i">
              {{ each }}
            </li>
          </ul>
          </p>
        </div>
        <div class="col-lg-6 col-md-12">
          <form @submit.prevent="submitApplication">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input type="text" name="name" id="name" class="form-control" required
                    data-error="Please enter your name" placeholder="Full Name" v-model="applicant.name" />
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input type="email" name="email" id="email" class="form-control" required
                    data-error="Please enter your email" placeholder="Email" v-model="applicant.email" />
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number"
                    class="form-control" placeholder="Mobile Number" v-model="applicant.mobile" />
                  <div class="help-block with-errors"></div>
                </div>
              </div>

              <div class="col-lg-12 col-mdm-12">
                <div class="form-group">
                  <input class="form-control" type="file" id="file" ref="file" accept=".pdf,.doc,.docx"
                    @change="selectFile" required />
                  <p>Upload Resume</p>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 text-center">
                <button type="submit" class="btn btn-primary">Apply Now</button>
                <div id="msgSubmit" class="h3 text-center hidden"></div>
                <div class="clearfix"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <!-- End Apply Modal -->
  </div>
</template>

<script>
import contactMixin from "../mixins/contact";
import swal from "sweetalert";

export default {
  name: "Careers",

  mixins: [contactMixin],

  data () {
    return {
      applicant: {},
      selectedJob: {},
      file: "",
      fileData: {},
      jobs: [
        // {
        //   position: "QA Associate Intern",
        //   aboutJob:
        //     "We are always looking at working with new talent to encourage working & evolving with newer technologies, have innovative ideas. We are looking for interns and freshers in multiple verticals - like technology, design and marketing.",
        //   responsibilities:
        //     "Must have knowledge of a broad range of software applications and of hardware and networks. A good understanding of business and demonstrated knowledge of testing best practices, version control practices and defect management practice",
        //   location: "Pune",
        //   experience: "Fresher",
        //   qualification: "Bachelor's degree or any equivalent in CS/IT",
        //   skills: "Enthusiasm",
        //   date: "Nov 18, 2022",
        // },
        // {
        //   position: "Digital Marketing - Fresher",
        //   aboutJob:
        //     "We are always looking at working with new talent to encourage working & evolving with newer technologies, have innovative ideas. We are looking for interns and freshers in multiple verticals - like technology, design and marketing.",
        //   responsibilities:
        //     "Google Analytics, Google Ads, SEO (on page - off page), Facebook Ads, Instagram, Email Marketing, Google Search Console, Google Tag Manager, Social Media Management & Marketing.",
        //   location: "Pune",
        //   experience: "Fresher",
        //   qualification: "Bachelor's degree or any equivalent",
        //   skills: "Enthusiasm",
        //   date: "Nov 14, 2022",
        // },
        // {
        //   position: "Web Developer (Part Time / Freelance)",
        //   aboutJob:
        //     "The Web Developer Engineer will be part of the software development team and will participate in software development project life cycle, including analysis, design, develop code, testing and documentations.",
        //   responsibilities:
        //     "Assist in defining, reviewing requirements and use cases for the application.Coordinate with teams in a variety of time zones to have smooth deliverables.Adapt to NodeJS, VueJS, MySQL.Perform assigned coding and testing activities.Create technical and functional/end-user operational documentation for the software or system",
        //   location: "Pune",
        //   experience: "2-3 Years",
        //   qualification: "Bachelor's degree or any equivalent in CS/IT",
        //   skills: "NodeJS/VueJS, MySQL",
        //   date: "May 29, 2024",
        // },
        // {
        //   position: "Web Developer Intern",
        //   aboutJob:
        //     "We are always looking at working with new talent to encourage working & evolving with newer technologies, have innovative ideas. We are looking for interns and freshers in multiple verticals - like technology, design and marketing.",
        //   responsibilities:
        //     "Must have exposure to web technologies like HTML, CSS and JS.Sound understanding of data-structures, algorithms.Knowledge about the web/network stack.Keen interest to be part of the most innovative technology team creating ground breaking technology products",
        //   location: "Pune",
        //   experience: "Fresher",
        //   qualification: "Bachelor's degree or any equivalent in CS/IT",
        //   skills: "Enthusiasm",
        //   date: "May 12, 2023",
        // },
        // {
        //   position: "UI/UX Designer",
        //   aboutJob:
        //     "We are looking for experienced UX Developers to join our team. Candidates with 1-3 years of experience will be considered.",
        //   responsibilities:
        //     "Gather and evaluate user requirements in collaboration with product managers and engineers.Illustrate design ideas using storyboards, process flows, and sitemaps.Develop UI mockups and prototypes that clearly illustrate how sites function and look like.Prepare and present rough drafts to internal teams and key stakeholders",
        //   location: "Pune",
        //   experience: "1 - 3 Years",
        //   qualification: "Bachelor's degree or any equivalent in CS/IT",
        //   skills: "",
        //   date: "Sept 10, 2022",
        // },
        // {
        //   position: "Graphic Designer",
        //   aboutJob:
        //     "The Graphic Designer job description includes the entire process of defining requirements, visualizing and creating graphics including Static Social media posts, logos, promotional posts, reels, banners and photos.",
        //   responsibilities:
        //     "Study design briefs and determine requirements.Conceptualize visuals based on requirements.Prepare rough drafts and present ideas.Develop illustrations, logos and other designs using software or by hand",
        //   location: "Pune",
        //   experience: "0 - 3 Years",
        //   qualification:
        //     "Bachelor's degree or any equivalent in design in CS/IT",
        //   skills: "Adobe Illustrator, Adobe Photoshop",
        //   date: "Sept 8, 2022",
        // },
      ],
    };
  },
  methods: {
    async selectFile () {
      this.file = await this.$refs.file.files[0];
    },

    async submitApplication () {
      try {
        this.applicant.position = this.selectedJob.position;

        const id = await this.SaveJobApplication(this.applicant);

        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("id", id);

        await this.UploadResume(formData);

        swal("Thank you for applying!", "", "success").then(() => {
          this.$bvModal.hide("apply-form");
        });
      } catch (e) {
        swal("Oops!", e.response.data.message, "error");
      }
    },
  },
};
</script>