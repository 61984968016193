<template>
  <!-- Start Fun Facts Area -->
  <section class="funfacts-area ptb-80">
    <div class="container">
      <div class="section-title">
        <h2>Everything starts with the customer</h2>
        <div class="bar"></div>
        <p>
          Believe in developing consistent and predictable experience that
          creates confidence, which can lead to loyalty.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>
              <span class="odometer">
                <IOdometer class="odometer" value="170" /> </span
              >+
            </h3>
            <p>Projects Delivered</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>
              <span class="odometer">
                <IOdometer class="odometer" value="8" /> </span
              >+
            </h3>
            <p>Years of Experienced</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>
              <span class="odometer">
                <IOdometer class="odometer" value="12" /> </span
              >+
            </h3>
            <p>Employees</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>
              <span class="odometer">
                <IOdometer class="odometer" value="4" /> </span
              >+
            </h3>
            <p>Countries We Serve Across</p>
          </div>
        </div>
      </div>

      <div class="contact-cta-box">
        <h3>Have any question about us?</h3>
        <p>Don't hesitate to contact us</p>
        <router-link to="/contact">
          <a href="javascript:void()" class="btn btn-primary">Contact Us</a>
        </router-link>
      </div>

      <div class="map-bg">
        <img src="@/assets/img/map.png" alt="map" />
      </div>
    </div>
  </section>
  <!-- End Fun Facts Area -->
</template>

<script>
import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css";

export default {
  name: "Funfacts",
  data() {
    return {};
  },
  components: {
    IOdometer,
  },
};
</script>