<template>
  <div>
    <!-- Start About Area -->
    <section class="agency-about-area ptb-80">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img src="@/assets/images/about-img.jpg" alt="image" />
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content text-justify">
              <span class="sub-title">About Us</span>
              <h2>Trecent Systems</h2>
              <div class="bar"></div>
              <p>
                Founded in 2021, Trecent Systems is a provider of software
                development services and consultancy. We are constantly empowering businesses by
                developing robust software solutions as per the business needs
                with the help of our in-house development team.
              </p>
              <p>
                Geographically based in India, we are headquarterd in Pune,
                Maharashtra from where the team drives the digital
                transformation for the businesses globally.
              </p>
              <p>
                With the team, over 10 years in Information Technology and Software Development, we have
                been able to establish a fruitful trust between our customers
                globally and continuously serving multiple non IT business
                domains like Health Care, Finance, Infrastructure, FMCG and
                manufacturing industries along with businesses in the IT domain.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="about-inner-area">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="about-text">
                <h3>Our History</h3>
                <p>
                  Founded in 2019, Trecent Systems has evolved
                  into a tech startup with its own in-house development team.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="about-text">
                <h3>Our Mission</h3>
                <p>
                  Design & develop products that will serve to betterment of the
                  businesses and the humanity world-wide
                </p>
              </div>
            </div>

            <div
              class="
                col-lg-4 col-md-6 col-sm-6
                offset-lg-0 offset-md-3 offset-sm-3
              "
            >
              <div class="about-text">
                <h3>Our Vision</h3>
                <p>
                  Transforming the future of IT and empowering the businesses
                  with smart and efficient software solutions for next level
                  output
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme">
        <img src="@/assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="@/assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="@/assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="@/assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="@/assets/img/shape2.svg" alt="shape" />
      </div>
    </section>
    <!-- End About Area -->

    <!-- <Statistics></Statistics> -->
    <Query></Query>
  </div>
</template>

<script>
import Statistics from "@/components/home/statistics";
import Query from "@/components/home/query";
export default {
  name: "AboutStyleThree",
  metaInfo: {
    title: "About Trecent Systems",
    titleTemplate: null,
    meta: [
      {
        vmid: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        name: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        content:
          "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
      },
    ],
  },
  components: {
    Statistics,
    Query,
  },
};
</script>