<template>
  <!-- Start IoT Main Banner -->
  <section class="iot-main-banner mb-5">
    <div class="container">
      <div class="iot-banner-content">
        <span>Technology is best when it brings people together</span>
        <h2>Unleashing the digital transformation!</h2>
        <p>
          Featuring abilities to transform the digital world with cutting-edge
          technologies for devleopment, processes, management and applications
          to realize the business efficiency is at its best.
        </p>
        <!-- <a href="#" class="btn btn-primary">Get Started</a> -->
      </div>

      <div class="iot-banner-image">
        <img
          src="@/assets/img/iot-banner-image/1.png"
          class="wow fadeInUp"
          v-wow
          data-wow-delay="1s"
          alt="image"
        />
        <img
          src="@/assets/img/iot-banner-image/2.png"
          class="wow zoomIn"
          alt="image"
        />
      </div>

      <div class="animate-border">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </section>
  <!-- End IoT Main Banner -->
</template>

<script>
export default {
  name: "Banner",
};
</script>