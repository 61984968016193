import VueRouter from 'vue-router';
import HomePage from './views/home';
import AboutPage from './views/about';
import ContactPage from './views/contact';
import CareersPage from './views/careers';
import ProductsPage from './views/products';
import GrillBillsLitePrivacy from './views/grillbills-lite-privacy.vue';
import NotFoundPage from './views/404';

export const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', component: HomePage },
    { path: '/about', component: AboutPage },
    { path: '/contact', component: ContactPage },
    { path: '/careers', component: CareersPage },
    { path: '/products', component: ProductsPage },
    { path: '/grillbills-privacy-policy', component: GrillBillsLitePrivacy },
    { path: '*', component: NotFoundPage, name: 'NotFound' }
  ]
});