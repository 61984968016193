<template>
  <!-- Start Footer Area -->
  <footer class="footer-area bg-f7fafd">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <div class="logo">
              <a href="/">
                <img
                  src="../../assets/images/logo-footer.png"
                  width="180"
                  alt="logo"
                />
              </a>
            </div>
            <p class="text-left">
              A trusted company delivering state-of-art software solutions,
              modernization and software transformation services for businesses
              across the globe.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-5">
            <h3>Services</h3>
            <ul class="list">
              <li><a href="/contact">Software Consulting</a></li>
              <li><a href="/contact">Product Engineering</a></li>
              <li><a href="/contact">SaaS Development</a></li>
              <li><a href="/contact">Mobile Applications</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Company</h3>
            <ul class="list">
              <li><a href="/about">About Us</a></li>
              <li><a href="/#services">Services</a></li>
              <li><a href="/products">Products</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Reach out to us!</h3>

            <ul class="footer-contact-info">
              <li>
                <feather type="mail"></feather> Email:
                <a href="javascript:void()">info@trecent.in</a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/Trecent+Systems/@18.4577968,73.8224176,17z/data=!3m1!4b1!4m5!3m4!1s0x3bc295b1a84b20fd:0x733f8e22914e69b7!8m2!3d18.4577968!4d73.8246063"
                  target="blank"
                >
                  <feather type="map-pin"></feather> Office no. 304, Vasantsakha
                  Plaza, Navale Bridge, Narhe, Pune - 411041
                </a>
              </li>
            </ul>
            <ul class="social-links">
              <li>
                <a
                  href="https://www.facebook.com/TrecentSystems/"
                  target="blank"
                  class="facebook"
                  ><feather type="facebook"></feather
                ></a>
              </li>
              <li>
                <a
                  href="https://twitter.com/trecentsystems"
                  target="blank"
                  class="twitter"
                  ><feather type="twitter"></feather
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/trecentsystems/"
                  target="blank"
                  class="instagram"
                  ><feather type="instagram"></feather
                ></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/trecentsystems"
                  target="blank"
                  class="linkedin"
                  ><feather type="linkedin"></feather
                ></a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="copyright-area text-left">
            <p>&copy; 2024 Trecent Systems Pvt. Ltd.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="copyright-area text-right">
            <router-link to="/grillbills-privacy-policy"
              >GrillBills&#8482; Privacy Policy</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <img src="../../assets/img/map.png" class="map" alt="map" />
    <div class="shape1">
      <img src="../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../assets/img/shape2.svg" alt="shape" />
    </div>
    <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

    <back-to-top bottom="50px" right="50px">
      <div class="go-top"><feather type="arrow-up"></feather></div>
    </back-to-top>
  </footer>
  <!-- End Footer Area -->
</template>

<script>
import BackToTop from "vue-backtotop";
export default {
  name: "Footer",
  components: { BackToTop },
};
</script>
