<template>
  <!-- Start Why Choose US Area -->
  <section class="why-choose-us ptb-80 pt-0">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="section-title">
            <h2>Exploring & Transforming the Future</h2>
            <div class="bar"></div>
            <p>
              Constantly figuring out the best ways for leveraging the
              technologies to drive the businesses. That's our passion! And how
              do we achieve that?
            </p>
          </div>

          <div class="why-choose-us-image">
            <img
              src="@/assets/img/why-choose-us-image/man-stand.png"
              class="wow fadeInLeft"
              alt="image"
            />
            <img
              src="@/assets/img/why-choose-us-image/database.png"
              class="wow fadeInRight"
              alt="image"
            />
            <img
              src="@/assets/img/services-left-image/cercle-shape.png"
              class="rotateme"
              alt="image"
            />
            <img
              src="@/assets/img/why-choose-us-image/main-static.png"
              class="main-pic wow fadeInUp"
              alt="image"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="single-why-choose-us">
                <div class="icon">
                  <i class="flaticon-team"></i>
                </div>
                <h3>Innovative Minds</h3>
                <p>
                  A strong team of explorers, innovators and developers tackling
                  the problems & conquering the solutions
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="single-why-choose-us">
                <div class="icon">
                  <i class="flaticon-rocket"></i>
                </div>
                <h3>Technology</h3>
                <p>
                  Using cutting-edge technologies to develop promising solutions
                  to critical business problems
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="single-why-choose-us">
                <div class="icon">
                  <i class="flaticon-shield"></i>
                </div>
                <h3>Simplicity & Trust</h3>
                <p>
                  Secure and simple approach is the key that creates the trust
                  between our clients & users
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="single-why-choose-us">
                <div class="icon">
                  <i class="flaticon-diamond"></i>
                </div>
                <h3>Mission & Vision</h3>
                <p>
                  Our strong values and culture connect and drive us forward to
                  deliver a sustainable future
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Why Choose US Area -->
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>