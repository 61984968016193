<template>
  <div>
    <!-- Start Page Title -->
    <div class="page-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Let's Connect!</h1>
            <h4 class="mt-2">
              Get in touch with us and let us know how we can help!
            </h4>
          </div>
        </div>
      </div>

      <div class="shape1">
        <img src="@/assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="@/assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="@/assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="@/assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img src="@/assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="@/assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="@/assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="@/assets/img/shape2.svg" alt="shape" />
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start Contact Info Area -->
    <section class="contact-info-area ptb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="mail"></feather>
              </div>
              <h3>Write to us @</h3>
              <p><a href="mailto:contact@trecent.in">contact@trecent.in</a></p>
              <p><a href="mailto:hr@trecent.in">hr@trecent.in</a></p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="map-pin"></feather>
              </div>
              <h3>Office Location</h3>
              <p>
                <a href="https://www.google.com/maps/place/Trecent+Systems/@18.4577968,73.8224176,17z/data=!3m1!4b1!4m5!3m4!1s0x3bc295b1a84b20fd:0x733f8e22914e69b7!8m2!3d18.4577968!4d73.8246063"
                  target="blank">304, Vasantsakha Plaza, Navale Bridge, Narhe, Pune,
                  Maharashtra, India - 411041</a>
              </p>
            </div>
          </div>

          <div class="
              col-lg-4 col-md-6 col-sm-6
              offset-lg-0 offset-md-3 offset-sm-3
            ">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="phone"></feather>
              </div>
              <h3>Quick Call</h3>
              <p><a href="#">+91-8605004319</a></p>
              <p><a href="#">+91-8806099932</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Info Area -->

    <!-- Start Contact Area -->
    <section class="contact-area ptb-80 pt-0">
      <div class="container">
        <div class="section-title">
          <h2>Get In Touch With Us</h2>
          <div class="bar"></div>
          <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-6 col-md-12">
            <img src="@/assets/images/contact.png" alt="image" />
          </div>

          <div class="col-lg-6 col-md-12">
            <form @submit.prevent="submitContact">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input type="text" name="name" id="name" class="form-control" required
                      data-error="Please enter your name" placeholder="Name" v-model="contact.name" />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input type="email" name="email" id="email" class="form-control" required
                      data-error="Please enter your email" placeholder="Email" v-model="contact.email" />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-6">
                  <div class="form-group">
                    <input type="text" name="phone_number" id="phone_number" required
                      data-error="Please enter your number" class="form-control" placeholder="Mobile"
                      v-model="contact.mobile" />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-6">
                  <div class="form-group">
                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                      data-error="Please enter your subject" placeholder="Subject" v-model="contact.subject" />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required
                      data-error="Write your message" placeholder="Message" v-model="contact.message"></textarea>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <button type="submit" class="btn btn-primary">
                    Send Message
                  </button>
                  <div id="msgSubmit" class="h3 text-center hidden"></div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Area -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "Contact",
  metaInfo: {
    title: "Trecent Systems | Exploring, Innovating, Transforming",
    titleTemplate: null,
    meta: [
      {
        vmid: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        name: "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
        content:
          "A trusted company delivering state-of-art software solutions, modernization and software transformation services for businesses across the globe.",
      },
    ],
  },
  data () {
    return {
      contact: {},
    };
  },
  methods: {
    submitContact () {
      this.sockets.subscribe("ERROR:CONTACT_SUBMIT", () => {
        Swal.fire(
          "Oh Sorry!",
          "You message did not reach to us. Try after sometime.",
          "error"
        );
      });

      this.sockets.subscribe("RESPONSE:CONTACT_SUBMIT", () => {
        Swal.fire(
          "We just received that!",
          "Thank you for reaching out. We'll connect very soon.",
          "success"
        );

        this.contact = {};
      });

      this.$socket.emit("REQUEST:CONTACT_SUBMIT", this.contact);
    },
  },
};
</script>