import axios from "axios";
import config from "../constant/config";


export default {
    methods: {
        async SaveJobApplication(applicationBody) {
            const res = await axios.post(
                `${config.BASE_URL}${config.API_ROUTES.CONTACT.JOB_APPLICATION}`,
                applicationBody
            );

            return res.data;
        },

        async UploadResume(file) {
            const res = await axios.post(`${config.BASE_URL}${config.API_ROUTES.CONTACT.UPLOAD_RESUME}`, file)

            return res.data
        }
    }
}