<template>
  <!-- Start ML Services Area -->
  <section class="ml-services-area ptb-80">
    <div class="container">
      <div class="section-title">
        <span class="sub-title">What services do we provide?</span>
        <h2>Featured Services</h2>
        <div class="bar"></div>
        <p>
          Our wide range of professional services are crafted with experience
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/software.png" alt="image" />
            </div>
            <h3><a href="#">Software Consulting</a></h3>
            <p>
              Working out on software needs for your business to help plan and
              implement the solutions according the requirements
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/ai.png" alt="image" />
            </div>
            <h3><a href="#">CV / ML / AI Consulting</a></h3>
            <p>
              Powering & driving businesses smartly with computer vision,
              machine learning and artificial intelligence
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/mobile.png" alt="image" />
            </div>
            <h3><a href="#">Mobile App Development</a></h3>
            <p>
              To cope up with the majority of technology users, we provide
              android & IOS development to meet the business needs
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/crm.png" alt="image" />
            </div>
            <h3><a href="#">Custom CRM Development</a></h3>
            <p>
              Custom development of telehealth systems according to market and
              business requirements
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/develop.png" alt="image" />
            </div>
            <h3><a href="#">IoT Development</a></h3>
            <p>
              Assessing IoT implementation and planning layers of network,
              devices, data processing and apps.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/modern.png" alt="image" />
            </div>
            <h3><a href="#">Software Modernization</a></h3>
            <p>
              Smooth transition of existing systems to modern technologies with
              disrupting the critical business operations
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/telehealth.png" alt="image" />
            </div>
            <h3><a href="#">Telehealth Systems</a></h3>
            <p>
              Custom development of telehealth systems according to market and
              business requirements
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/cloud.png" alt="image" />
            </div>
            <h3><a href="#">Cloud Consulting</a></h3>
            <p>
              Providing cloud consulting & management to ensure the data safety
              and smooth flow of your applications
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="image">
              <img src="@/assets/images/services/product.png" alt="image" />
            </div>
            <h3><a href="#">Product Consulting</a></h3>
            <p>
              Analyzing the target markets for your product, prioritizing the
              features and for an informed market entry
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="shape1"><img src="@/assets/img/shape1.png" alt="shape" /></div>
    <div class="shape2 rotateme">
      <img src="@/assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3"><img src="@/assets/img/shape3.svg" alt="shape" /></div>
    <div class="shape4"><img src="@/assets/img/shape4.svg" alt="shape" /></div>
    <div class="shape6 rotateme">
      <img src="@/assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7"><img src="@/assets/img/shape4.svg" alt="shape" /></div>
    <div class="shape8 rotateme">
      <img src="@/assets/img/shape2.svg" alt="shape" />
    </div>
  </section>
  <!-- End ML Services Area -->
</template>

<script>
export default {
  name: "Services",
};
</script>